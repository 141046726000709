import React from "react";
import { useTranslation } from "react-i18next";
import { css } from "goober";
import { LoadingSpinner } from "clutch/src/LoadingSpinner/LoadingSpinner";

import { readState } from "@/__main__/app-state.mjs";
import { GAME_BOX_ICONS } from "@/app/constants.mjs";
import { SKILL_HOTKEYS } from "@/game-lol/constants/constants.mjs";
import { GAME_SYMBOL_LOL } from "@/game-lol/definition-symbol.mjs";
import Static from "@/game-lol/utils/static.mjs";
import { getStaticData } from "@/game-lol/utils/util.mjs";
import ArrowRight from "@/inline-assets/arrow-right2.svg";
import appendUtm from "@/marketing/append-utm.mjs";
import AnchorCard from "@/shared/AnchorCard";
import { useSnapshot } from "@/util/use-snapshot.mjs";

function HomeChampionReleaseTile({ championKey, releaseDate, rework = false }) {
  const { volatile } = useSnapshot(readState);
  const { t } = useTranslation();

  void volatile.lolStaticDataLoaded;

  const champions = getStaticData("champions");
  const championId = Static.getChampionIdFromKey(champions, championKey);
  const champion = Object.values(champions)?.find(
    (champion) => champion.key === championId,
  );

  if (!champion) return <LoadingSpinner $position="relative" />;

  const href = appendUtm(`/lol/champions/${championKey}`, {
    utm_source: "blitz",
    utm_medium: "featured_tile",
    utm_campaign: "landing",
  });

  const recentRelease =
    new Date(releaseDate) > new Date(Date.now() - 28 * 24 * 60 * 60 * 1000);

  return (
    <AnchorCard className={cssCard()}>
      <a href={href}>
        <div
          className="background"
          style={{
            "--src": `url(${Static.getChampionCenterImage(championKey)})`,
          }}
        />
        <div className="text-block">
          <h3 className="type-article-headline">
            <span>
              {champion?.name}
              {recentRelease && (
                <span className="tag type-caption--semi">
                  {t("common:new", "New")}
                </span>
              )}
            </span>
            {React.createElement(GAME_BOX_ICONS[GAME_SYMBOL_LOL], {
              className: "original-colors",
            })}
          </h3>

          <p className="type-body2">
            {!rework
              ? t("lol:checkOutNewChampion", "Check out the new champion.")
              : t(
                  "lol:checkOutReworkedChampion",
                  "Check out the reworked champion.",
                )}
          </p>

          <div className="link">
            <span className="type-form--button">
              {t("lol:viewChampion", "View Champion")}
            </span>

            <ArrowRight width={12} />
          </div>
        </div>
        <div className="spells">
          {champion?.spells?.map((spell, index) => (
            <div key={index}>
              <img
                src={
                  index === 0
                    ? Static.getChampionPassiveImageById(spell.image.full)
                    : Static.getChampionSpellImageById(spell.id)
                }
                alt={spell.name}
              />
              <span>{index > 0 ? SKILL_HOTKEYS[index - 1] : "P"}</span>
            </div>
          ))}
        </div>
      </a>
    </AnchorCard>
  );
}

export default HomeChampionReleaseTile;

const cssCard = () => css`
  overflow: hidden;

  border-radius: var(--br-lg);

  .background {
    background-image:
      radial-gradient(
        50% 100% at 50% 100%,
        rgba(24, 27, 33, 0) 50%,
        rgba(24, 27, 33, 0.5) 100%
      ),
      radial-gradient(
        50% 100% at 50% 100%,
        rgba(24, 27, 33, 0) 50%,
        #181b21 100%
      ),
      var(--src);
    height: 377.031px;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center 70px;
    color: white;
    transform: scale(1.6);
  }

  .text-block {
    display: flex;
    position: absolute;
    top: 0;

    flex-direction: column;
    align-items: flex-start;
    gap: var(--sp-3);

    width: 100%;
    padding: var(--sp-6);

    transform: translateZ(var(--parallax));

    pointer-events: none;

    h3 {
      display: flex;
      align-items: center;
      justify-content: space-between;

      width: 100%;

      color: var(--shade0);

      span.tag {
        margin-left: var(--sp-2);
        padding: var(--sp-0_5) var(--sp-1_5);

        color: var(--turq);

        background-color: var(--turq-15);
        border-radius: var(--br);
      }
    }

    svg,
    h3 > svg {
      width: var(--sp-5);
      height: var(--sp-5);
    }

    p {
      color: var(--shade1);
    }

    & > .link {
      display: flex;
      align-items: center;
      gap: var(--sp-2);

      color: var(--shade2);
    }
  }

  .spells {
    display: flex;
    position: absolute;
    bottom: 0;

    width: 100%;
    margin-bottom: var(--sp-10);

    justify-content: center;
    gap: var(--sp-2);

    div {
      position: relative;
    }

    img {
      height: var(--sp-10);
      width: var(--sp-10);
      border-radius: var(--br);
    }

    span {
      background: var(--shade10);
      border-radius: 4px 0 4px 0;
      bottom: 0;
      height: var(--sp-4);
      font-size: 0.875rem;
      line-height: 19px;
      position: absolute;
      right: 0;
      text-align: center;
      width: var(--sp-4);
      pointer-events: none;
    }
  }
`;
